<template>
  <div class="notification">
    <heard-top></heard-top>
    <!-- 内容 -->
    <div class="notice">
      <div class="notice-bg-center">
        <div class="notice-title text-main">全部通知</div>
        <div class="notice-item">
          <div class="item" v-for="item in list" :key="item.create_time">
            <div class="text-main">{{ item.create_time }}</div>
            <div class="row">
              <img src="../../assets/glxt/12.png" alt="" />
              <div class="item-text">
                <div>{{ item.title }}</div>
                <div v-html="item.content" style="word-break:break-all;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="nomore-text" v-if="list.length==0">暂无数据</div>
      <div class="pading">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :hide-on-single-page="valuePageShow"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>
    <feet-model></feet-model>
  </div>
</template>

<script>
import heardTop from "../../components/loggedHeard.vue";
import feetModel from "../../components/feet.vue";
import api from "../../api/tool";
import { mapGetters, mapState } from "vuex";
export default {
    computed: {
    ...mapGetters(["hasLogin"]),
    ...mapState(["userDetail"]),
  },
  components: {
    heardTop,
    feetModel,
  },

  data() {
    return {
      total:0,
      list: [],
      valuePageShow: true,
      query: {
        page: 1,
        user_type:1,
        pageSize:10
      },
    };
  },

  created() {
    if (this.userDetail.user_type == 2) {
      this.toggleClick();
      return;
    }
    this.noticeList();
  },

  mounted() {},

  methods: {
    toggleClick() {
      this.$emit("toggleClick");
    },
    // 页数跳转
    currentChange(val) {
      this.query.page = val;
      this.noticeList();
    },
    noticeList() {
      api.getNotice(this.query).then((res) => {
        this.total = res.data.total;
        if (res.code == 200) {
          this.list = res.data.data;
        }
         if (res.data.last_page > 1) {
            this.valuePageShow = true;
          }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.notification {
  width: 100%;
  background: #f6f6f8;
  min-height: 100vh;
  .notice {
    width: 100%;
    padding: 1.25rem 11.875rem 0 11.875rem;
    .notice-bg-center {
      width: 100%;
      padding: 1.25rem 1.875rem 0 1.875rem;
      background: #fff;
      .notice-title {
        font-size: 1.5rem;
        margin-bottom: 3.125rem;
      }
      .notice-item {
        .item {
          margin-bottom: 2rem;
          padding-bottom: 1.875rem;
          border-bottom: 1px solid #eee;

          > div:first-child {
            font-size: 16px;
            margin-bottom: 1.1875rem;
          }

          img {
            width: 2.0625rem;
            height: 2.0625rem;
            margin-right: 0.625rem;
          }
          .item-text {
            font-size: 0.875rem;
            color: #51586d;
            div:first-child {
              margin-bottom: 0.25rem;
            }
          }
        }
        .item:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }
      }
    }
    .nomore-text {
      text-align: center;
      color: #999999;
      font-size: 0.8125rem;
      margin-top: 1.9375rem;
      padding-bottom: 10px;
    }
  }
    .pading {
        margin: 10px 0;
        display: flex;
        justify-content: center;
        ::v-deep.btn-prev {
          background: #fff;
        }
        ::v-deep .el-pagination.is-background .btn-next,
        .el-pagination.is-background .btn-prev,
        .el-pagination.is-background .el-pager li {
          background: #fff;
        }
        ::v-deep .el-pagination.is-background .el-pager li {
          background: #fff;
          color: #414a60;
        }
        ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
          background: #126bf9;
          color: #fff;
        }
      }
}
</style>
